<template>
  <main style="padding-top: 40px; padding-bottom: 40px; background-color: white">
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mb-5">
            <div class="heading_container" style="text-align: center">
              <h2 style="color: #ef5b0c">Let's get in touch</h2>
            </div>
          </div>
        </div>
        <div class="responsive-box" style="border-radius: 20px; background-color: #fde5d1">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="wrapper">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                    <div class="contact-wrap w-100 p-md-5 p-4" style="text-align: left">
                      <form id="contact" target="_blank" action="https://formsubmit.co/info@devalayas.com"
                        method="POST">
                        <input type="hidden" name="_cc"
                          value="preetham2u@gmail.com,arthur.ps@gmail.com,info@devalayas.com" />
                        <input type="hidden" name="_subject" value="DEVALAYAS.IN New Form Submission!" />
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="label" for="subject">Full Name</label>
                              <input type="text" name="name" class="form-control" placeholder="Name" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="label" for="name">Phone Number</label>
                              <!--  -->
                              <input type="phone" name="phone" class="form-control" placeholder="Phone Number" />
                              <!--  -->
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="label" for="email">Email Address</label>
                              <input type="email" name="email" class="form-control" placeholder="Email Address" />
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="label" for="#">Message</label>
                              <textarea placeholder="Your Message" class="form-control" name="message"
                                rows="10"></textarea>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <button type="submit" class="btn btn-lg btn-dark btn-block" style="margin-top: 20px">
                              Submit Your Details
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-5 d-flex align-items-stretch">
                    <div class="w-100 p-md-5 p-4 rounded-2 image-container">
                      <h3 style="color:white">Let's Talk</h3>
                      <p class="mb-4" style="color:white">
                        We're open for any suggestion or just to have a chat
                      </p>
                      <br>
                      <div class="dbox w-100 d-flex align-items-start">
                        <div class="icon d-flex align-items-center justify-content-center"></div>
                        <div class="text pl-3">                          
                          <p style="color:white; text-align:left">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            Address: CTS NO 4824/C7, S P &nbsp;&nbsp;&nbsp; OFFICE ROAD, BELAGAVI,
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Karnataka, 590016
                            <!-- <span class="fa fa-map-marker">
                              <span>&nbsp;Address:&nbsp;</span>
                              <span>CTS NO 4824/C7, S P OFFICE ROAD, BELAGAVI,
                                Karnataka, 590016</span>
                            </span> -->
                          </p>
                        </div>
                      </div>
                      <br>
                      <div class="dbox w-100 d-flex align-items-center">
                        <div class="icon d-flex align-items-center justify-content-center"></div>
                        <div class="text pl-3">
                          <p style="color:white">
                            <span class="fa fa-phone">
                              <span>&nbsp;Phone:&nbsp;</span>
                              <span>+91 8951 269 111</span>
                            </span>
                          </p>
                        </div>
                      </div>
                      <br>
                      <div class="dbox w-100 d-flex align-items-center">
                        <div class="icon d-flex align-items-center justify-content-center">
                          <!-- <span class="fa fa-paper-plane"></span> -->
                        </div>
                        <div class="text pl-3">
                          <p style="color:white">
                            <span class="fa fa-paper-plane">
                              <span>&nbsp;Email:&nbsp;</span>
                              <span>info@devalayas.com</span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <div class="container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3837.626866438575!2d74.51211637470792!3d15.876196744577495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbf61526e31b4c7%3A0x6e6ea4da02166755!2sANEK%20TECHNOLOGIES%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1709534412908!5m2!1sen!2sin"
        width="100%"
        height="450"
        style="border: 0"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div> -->
    <!-- <div
      class="responsive-box"
      style="width: 80%; background-color: #fde5d1; border-radius: 20px">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 align-self-center">
            <div class="left-text-content">
              <div class="section-heading">
                <h4 class="col-12 text-center">Contact Us</h4>
                <h2>Please be free to suggest a temple or a pandit ji</h2>
              </div>
              <p>
                Together we can make deference in bringing Hindu Religion as
                worlds most worshiped Gods and Educate our young generations in
                our Hindu Spirituals<br /><br />POSTAL ADDRESS: CTS NO 4824/C7,
                S P OFFICE ROAD, BELAGAVI, Karnataka, 590016
              </p>
              <div style="padding-top: 20px" class="row">
                <div class="col-lg-6">
                  <div class="phone">
                    <i class="fa fa-phone"></i>
                    <h4>Phone Numbers</h4>
                    <span
                      ><a href="#"
                        >+91 8951 269 111 <br />(Only Weekday 11AM to 4PM)</a
                      ></span
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="message">
                    <i class="fa fa-envelope"></i>
                    <h4>Emails</h4>
                    <span
                      ><a href="#">Query: support@devalayas.com</a><br /><a
                        href="#"
                        >Suggestion: info@devalayas.com</a
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="contact-form">
              <form
                id="contact"
                target="_blank"
                action="https://formsubmit.co/support@devalayas.com"
                method="POST"
              >
                <input
                  type="hidden"
                  name="_cc"
                  value="preetham2u@gmail.com,arthur.ps@gmail.com,info@devalayas.com"
                />
                <input
                  type="hidden"
                  name="_subject"
                  value="DEVALAYAS.IN New Form Submission!"
                />
                <div class="row">
                  <h4 class="col-12 text-center">Lets Talk</h4>
                  <div class="col-lg-6 col-sm-12">
                    <fieldset>
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        placeholder="Full Name"
                      />
                    </fieldset>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <fieldset>
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="Email Address"
                      />
                    </fieldset>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <fieldset>
                      <input
                        type="phone"
                        name="phone"
                        class="form-control"
                        placeholder="Phone Number"
                      />
                    </fieldset>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <fieldset>
                      <input
                        type="place"
                        name="place"
                        class="form-control"
                        placeholder="Your Place"
                      />
                    </fieldset>
                  </div>
                </div>
                <div class="form-group">
                  <textarea
                    placeholder="Your Message"
                    class="form-control"
                    name="message"
                    rows="10"
                  ></textarea>
                </div>
                <button type="submit" class="btn btn-lg btn-dark btn-block">
                  Submit Your Details
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </main>
</template>
<style>
.bg-primary {
  background-color: #c88fbd;
  border-radius: 20px;
}

.phone {
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 0px 0px 20px 0px;
}

.message {
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 0px 0px 20px 0px;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.section-heading h2 {
  color: #fff;
  font-weight: 700;
}

.section-heading h2 {
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: 900;
  color: #2a2a2a;
}

.p {
  color: #fff;
  margin-bottom: 65px;
}

.p {
  font-size: 14px;
  line-height: 25px;
  color: #2a2a2a;
}

.contact {
  margin-left: 30px;
  padding: 45px 30px;
  background-color: #fff;
  border-radius: 5px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contact-form textarea {
  height: 150px;
  resize: none;
  padding: 20px;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.contact h4 {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  color: #2a2a2a;
  margin-bottom: 30px;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  color: #2a2a2a;
  font-size: 14px;
  border: 1px solid #ddd;
  background-color: #fff;
  width: 100%;
  height: 46px;
  border-radius: 5px;
  outline: none;
  padding-top: 3px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 15px;
}

.col-lg-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
  background-color: #fde5d1;
}

.message i {
  width: 70px;
  height: 70px;
  display: inline-block;
  text-align: center;
  line-height: 70px;
  color: #fff;
  background-color: #fb5849;
  border-radius: 50%;
  font-size: 24px;
  margin-top: -35px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.phone i,
#reservation .message i {
  width: 70px;
  height: 70px;
  display: inline-block;
  text-align: center;
  line-height: 70px;
  color: #fff;
  background-color: #fb5849;
  border-radius: 50%;
  font-size: 24px;
  margin-top: -35px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.contact-form button {
  margin-top: -10px;
  font-size: 13px;
  color: #fff;
  background-color: #fb5849;
  padding: 12px 25px;
  width: 100%;
  border: none;
  display: inline-block;
  border-radius: 3px;
  font-weight: 600;
  transition: all 0.3s;
}

.image-container {
  background-image: url('@/assets/map.jpg');
  background-size: cover;
  background-position: center;
}
</style>
